.flagIcon {
  margin-right: 12px;
}

.container {
  width: fit-content;
  max-width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: auto;
}

.select {
  width: 208px;
}

.checkbox {
  margin-bottom: 8px;
}

.button {
  width: fit-content;
}

.optionContent {
  display: flex;
  gap: 8px;
  align-items: center;
}

.optionText {
  font-size: 14px;
}

.freemiumUpgrade {
  position: relative;
  width: 100%;
  background: var(--elevation-default);

  .pageSection {
    max-width: 1280px;
    padding-inline: 48px;
  }

  .wrapper {
    height: 100%;
    overflow-y: auto;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-block: 48px;
      margin: 0 auto;
      text-align: center;

      .pageSubtitle {
        max-width: 783px;
        margin: 0 auto;
        margin-bottom: 32px;
      }

      .marketingCarouselWrapper {
        margin-bottom: 80px;
      }

      .marketingCards {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        margin-bottom: 40px;

        :first-child {
          grid-row: span 2;
        }
      }

      .upgradeFeatures {
        display: flex;
        gap: 80px;
        margin: 80px 0;
        text-align: left;

        > * {
          flex: 1;
        }

        .upgradeFeaturesText {
          display: flex;
          flex-direction: column;
          justify-content: center;

          h2 {
            max-width: 470px;
          }
        }
      }

      .testimonials {
        padding-block: 40px 138px;

        .testimonialsWrapper {
          display: flex;
          gap: 24px;
          margin-bottom: 32px;

          > * {
            flex: 1;
          }
        }

        .trustpiolotScore {
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: center;

          img {
            width: 82px;
          }
        }
      }
    }
  }

  .marketingButtonWrapper {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
  }
}

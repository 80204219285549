.marketingCarousel {
  position: relative;
  overflow: hidden auto;

  .carouselButton {
    position: absolute;
    top: 120px;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    &.visible {
      pointer-events: auto;
      opacity: 1;
    }

    &.leftButton {
      /* -: calc((center) - (half image width) - (button size) - (space between image and button)) */
      left: calc(50% - 150px - 48px - 65px);
    }

    &.rightButton {
      /* -: calc((center) - (half image width) - (button size) - (space between image and button)) */
      right: calc(50% - 150px - 48px - 65px);
    }
  }

  .item {
    --slide-size: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.2;
    transition:
      opacity 0.25s ease-in-out,
      scale 0.25s ease;
    scale: 0.8;

    p {
      max-width: 535px;
    }

    &.visible {
      opacity: 1;
      scale: 1;
    }

    .lottie {
      width: 300px;
      height: 300px;
    }
  }
}
